
import * as React from "react";
import { styled } from "@mui/material/styles";
import TableContainer from "@mui/material/TableContainer";
import { Paper, } from "@mui/material";
import 'react-phone-input-2/lib/style.css';
import { useState, useEffect } from "react";
import { APIPath, token } from "../../config/api";

const StyledTableContainer = styled(TableContainer)(({ theme, open }) => ({
    width: open ? "80%" : "94%",
    transition: "width 0.3s ease",
    paddingTop: "7px",
    marginLeft: open ? "243px" : "69px",
    position: "relative",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    overflow: "hidden",
    marginBottom: "20px",
    boxShadow: "none",
}));

const AddMarkupProduct = ({ open,onClose,Id,productName,b2bMarkup,b2cMarkup}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [product, setProduct] = useState(productName || "");
    const [b2b, setb2b] = useState(b2bMarkup || '');
    const [b2c, setb2c] = useState(b2cMarkup || '');

    const addMarkupObj = {
        productName: product,
        b2bMarkup: parseInt(b2b),
        b2cMarkup: parseInt(b2c),
    }
    const addMarkup = () => {
        setIsLoading(true)
        fetch(`${APIPath}/api/v1/agency/markup`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(addMarkupObj)
        })
            .then((res) => res.json())
            .then((data) => {
                setIsLoading(false);
                // alert("Markup added for this product...");
                alert(data.message);
                onClose();
            })
            .catch((err) => {
                alert(err)
            })
    }
    const editMarkup=()=>{
        setIsLoading(true)
        fetch(`${APIPath}/api/v1/agency/markup?id=${Id}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            method: 'PATCH',
            mode: 'cors',
            body: JSON.stringify(addMarkupObj)
        })
            .then((res) => res.json())
            .then((data) => {
                setIsLoading(false);
                // alert("Markup updated for this product...");
                alert(data.message);
                onClose();
            })
            .catch((err) => {
                alert(err)
            })
    }

    return (
        <StyledTableContainer component={Paper} open={open}>
             <div className="add-country-container">
                <div className="add-country-form">
                    <h2>Add Markup for New Product</h2>
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        addMarkup();
                    }}>
                        <div className="add-country-name" style={{ width: "100%", marginBottom: "10px" }}>
                            <label>Select Product *</label>
                            <select value={product} onChange={(e) => { setProduct(e.target.value) }}
                               disabled={productName.length > 0 ? true : false}
                                required 
                            >
                                <option value="" disabled>Select product</option>
                                <option value="TRANSFER">Transfers</option>
                                <option value="PACKAGE">Packages</option>
                                <option value="ATTRACTION">Attractions</option>
                                <option value="LANDCOMBO">Land Combos</option>
                            </select>
                        </div>
                        <div className="add-country-name">
                            <label>Add Markup for B2B (in %) *</label>
                            <input style={{ marginBottom: "10px" }} onChange={(e) => { setb2b(e.target.value) }}
                                value={b2b}
                                type="number"
                                placeholder="0"
                                min={0}
                                max={100}
                                required
                            />
                        </div>
                        <div className="add-country-name">
                            <label>Add Markup for B2C (in %) *</label>
                            <input style={{ marginBottom: "1rem" }} onChange={(e) => { setb2c(e.target.value) }}
                                value={b2c}
                                type="number"
                                placeholder="0"
                                min={0}
                                max={100}
                                required
                            />
                        </div>
                        <div className="add-country-btn">
                            {isLoading ? (
                                <div className="loader"></div>
                            ) : (
                                <>
                                    <button onClick={onClose} id="cancel">Cancel</button>
                                    {b2bMarkup > 0 ?
                                    <button id="addvehicle"
                                    onClick={editMarkup}
                                    >update Markup</button> :
                                    <button type="submit" id="addvehicle" >Add Markup</button>
                                    }
                                </>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </StyledTableContainer>

    );
}

export default AddMarkupProduct;
import React, { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { APIPath, token } from "../../../config/api";
import axios from 'axios';
import './CustomBooking.css';

const BookCustomDetails = ({ onClose, bookingId }) => {
    document.body.style.overflow = 'hidden';
    const [isLoading, setIsLoading] = useState(false);
    const [arrowClickP, setArrowClickP] = useState(false);
    const [arrowClickA, setArrowClickA] = useState(false);
    const [arrowClickLnC, setArrowClickLnC] = useState(false);
    const [arrowClickT, setArrowClickT] = useState(false);
    const [bookingStatus, setBookingStatus] = useState('')
    const [gatewayType, setGatewayType] = useState("STRIPE");
    const [rejection, setRejection] = useState(false);
    const [rejectionText, setRejectionText] = useState("");
    const [onhold, setOnhold] = useState(false);
    const [onholdText, setOnholdText] = useState("");
    const [status, setStatus] = useState('');
    const [customer, setCustomer] = useState(null)
    const [agentName, setAgentName] = useState('');
    const [agentPhone, setAgentPhone] = useState('');
    const [agentEmail, setAgentEmail] = useState('');
    const [packageData, setPackageData] = useState(null);
    const [attractionData, setAttractionData] = useState(null);
    const [landComboData, setLandComboData] = useState(null);
    const [transferData, setTransferData] = useState(null);
    const [totalCost, setTotalCost] = useState();

    useEffect(() => {
        setIsLoading(true)
        fetch(`${APIPath}/api/v1/agency/bookings/customs?id=${bookingId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            method: 'GET',
            mode: "cors",
        })
            .then((res) => res.json())
            .then((data) => {
                setIsLoading(false);
                setCustomer(data.data[0].customerDetails);
                setTotalCost(data.data[0].totalCost)
                setAgentName(data.data[0].agentName);
                setAgentPhone(data.data[0].agentPhoneNumber);
                setAgentEmail(data.data[0].agentEmail)
                setPackageData(data.data[0]?.packages);
                setAttractionData(data.data[0]?.attractions);
                setLandComboData(data.data[0]?.landCombos);
                setTransferData(data.data[0]?.transfers);
                setBookingStatus(data.data[0]?.bookingStatus)
            })
            .catch((error) => {
                console.error('Error fetching Package list:', error);
                setIsLoading(false);
            });
    }, [bookingId]);

    const rejectRequest = () => {
        // console.log("Request Rejected ")
        setRejection(true)
        setStatus("Rejected");
    }
    const reasonClose = () => {
        setRejection(false);
        setOnhold(false)
        setStatus('');
    }
    const onHoldRequest = () => {
        // console.log("request On Hold");
        setOnhold(true)
        setStatus("On Hold")
    }
    let reasonMsg = {
        status: status,
        reason: status === "Rejected" ? rejectionText : onholdText,
    }
    const rejectBooking = () => {
        if (rejectionText.length > 5) {
            axios.patch(`${APIPath}/api/v1/agency/bookings/customs?id=${bookingId}`, reasonMsg, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
                .then((response) => {
                    console.log(response.data);
                    if (response.status === 200) {
                        alert("You are rejecting this Request");
                        setRejectionText("");
                        reasonClose();
                        onClose()
                    }
                })
                .catch((error) => {
                    console.error('Error While Rejecting:', error);
                });
        } else {
            alert("Please provide valid 'Rejection' Reason");
            return;
        }
    }
    const onHoldBooking = () => {
        if (onholdText.length > 5) {
            axios.patch(`${APIPath}/api/v1/agency/bookings/customs?id=${bookingId}`, reasonMsg, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
                .then((response) => {
                    console.log(response.data);
                    if (response.status === 200) {
                        alert("You are Putting this request 'On Hold'.");
                        setOnholdText("");
                        reasonClose();
                        onClose();
                    }
                })
                .catch((error) => {
                    console.error('Error While put On Hold:', error);
                });
        } else {
            alert("Please provide valid 'On Hold' Reason");
            return;
        }
    }
    const [loading, setLoading] = useState(false);
    const [count, SetResendCount] = useState(0);
    const sendPaymentLink = () => {
        if (bookingStatus === 'Confirmed') {
            alert("Booking already Confirmed")
            return;
        }
        if (count >= 3) {
            alert("Payment link already sent 3 times. Please check your email or contact support for assistance.");
            return;
        }
        else {
            setLoading(true);
            fetch(`${APIPath}/api/v1/agency/payments/transfer/payment-link?id=${bookingId}&gatewayType=${gatewayType}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                    method: "POST",
                    mode: "cors",
                }
            )
                .then((res) => res.json())
                .then((data) => {
                    setLoading(false)
                    alert(data.message);
                    onClose();
                })
                .catch((err) => {
                    alert(err);
                    setIsLoading(false)
                });
        }
    }


    return <>
        <div className="booking-details-container">
            <div className="booking-details-page"
            style={{
                // maxHeigh:"600px",
                height:'600px'
            }}
            >
                {isLoading ? (
                    <div className="loader">
                    </div>) : (
                    <>
                        <div className="booking-pkg-pass-header">
                            <h4>Booking Details</h4>
                            <h2 onClick={onClose}><IoMdClose /></h2>
                        </div>
                        <div
                            style={{
                                display: "flex", flexDirection: "column",height: "88%",
                                justifyContent: "space-between"
                            }}
                        >
                            <div className="custom-booking-total">
                                <div className="booking-custom-details">
                                    <h2 style={{ color: "#1D3071", fontSize: "16px" }}>Lead Passenger Details</h2>
                                </div>
                                <div className="booking-pkg-pass-details">
                                    <div className="booking-custom-details-child">
                                        <h4>Name</h4>
                                        <p>{customer?.name}</p>
                                    </div>
                                    <div className="booking-custom-details-child">
                                        <h4>Mobile No.</h4>
                                        <p>+{customer?.phone}</p>
                                    </div>
                                    <div className="booking-custom-details-child">
                                        <h4>Email</h4>
                                        <p>{customer?.email}</p>
                                    </div>
                                </div>
                                <hr color="#ccc" />
                                {packageData && packageData.length > 0 && (
                                    // <div className={arrowClickP ? "fixed-height":"booking-pkg-custom-details"}>
                                    <div className="booking-pkg-custom-details">
                                        <div className="booking-pkg-custom-header">
                                            <h2 style={{ color: "#1D3071", fontSize: "16px" }}>Package Details</h2>
                                            {arrowClickP ? <img src="./up-arrow.svg"
                                                onClick={() => {
                                                    setArrowClickP(!arrowClickP)
                                                }}
                                            /> :
                                                <img src="./down-arrow.svg"
                                                    onClick={() => {
                                                        setArrowClickP(!arrowClickP)
                                                    }}
                                                />}

                                        </div>
                                        {packageData.map((packages, id) => {
                                            return <>
                                                <div className={arrowClickP ? "booking-custom-details-package" : "custom-none"} >
                                                    <div className="booking-custom-details-child">
                                                        <h4>Title</h4>
                                                        <p>{packages.title}</p>
                                                    </div>
                                                    <div className="booking-custom-details-child">
                                                        <h4>Duration</h4>
                                                        <p>{packages?.duration}</p>
                                                    </div>
                                                    <div className="booking-custom-details-child">
                                                        <h4>Date</h4>
                                                        <p>{packages.startDate.split("T")[0]}</p>
                                                    </div>
                                                    <div className="booking-custom-details-child">
                                                        <h4>Adults</h4>
                                                        <p>{packages.numberOfAdults}</p>
                                                    </div>
                                                    <div className="booking-custom-details-child">
                                                        <h4>Children</h4>
                                                        <p>{packages.numberOfChildrens}</p>
                                                    </div>
                                                    <div className="booking-custom-details-child">
                                                        <h4>Infants</h4>
                                                        <p>{packages.numberOfInfants}</p>
                                                    </div>
                                                    <div className="booking-custom-details-child">
                                                        <h4>Price</h4>
                                                        <p><b>AED {packages.cost}</b></p>
                                                    </div>
                                                </div>
                                                {((id + 1) < packageData.length) && arrowClickP && (
                                                    <>
                                                        <hr />
                                                    </>
                                                )}
                                            </>
                                        })}
                                        <hr color="#ccc" />
                                    </div>
                                )}
                                {attractionData && attractionData.length > 0 && (
                                    <div className="booking-pkg-custom-details">
                                        <div className="booking-pkg-custom-header">
                                            <h2 style={{ color: "#1D3071", fontSize: "16px" }}>Attraction Details</h2>
                                            {arrowClickA ? <img src="./up-arrow.svg"
                                                onClick={() => {
                                                    setArrowClickA(!arrowClickA)
                                                }}
                                            /> :
                                                <img src="./down-arrow.svg"
                                                    onClick={() => {
                                                        setArrowClickA(!arrowClickA)
                                                    }}
                                                />}

                                        </div>
                                        {attractionData.map((attractions, id) => {
                                            return <>
                                                <div className={arrowClickA ? "booking-custom-details-package" : "custom-none"}  >
                                                    <div className="booking-custom-details-child">
                                                        <h4>Title(Category)</h4>
                                                        <p>{attractions.title}</p>
                                                        <p><b>({attractions.subTitle})</b></p>
                                                    </div>
                                                    <div className="booking-custom-details-child">
                                                        <h4>Date</h4>
                                                        <p>{attractions.startDate.split("T")[0]}</p>
                                                    </div>
                                                    <div className="booking-custom-details-child">
                                                        <h4>Adults</h4>
                                                        <p>{attractions.numberOfAdults}</p>
                                                    </div>
                                                    <div className="booking-custom-details-child">
                                                        <h4>Children</h4>
                                                        <p>{attractions.numberOfChildrens}</p>
                                                    </div>
                                                    <div className="booking-custom-details-child">
                                                        <h4>Infants</h4>
                                                        <p>{attractions.numberOfInfants}</p>
                                                    </div>

                                                    <div className="booking-custom-details-child">
                                                        <h4>Price</h4>
                                                        <p><b>AED {attractions.cost}</b></p>
                                                    </div>
                                                </div>
                                                {((id + 1) < attractionData.length) && arrowClickA && (
                                                    <>
                                                        <hr />
                                                    </>
                                                )}
                                            </>
                                        })}
                                        <hr color="#ccc" />
                                    </div>
                                )}
                                {landComboData && landComboData.length > 0 && (
                                    <div className="booking-pkg-custom-details">
                                        <div className="booking-pkg-custom-header">
                                            <h2 style={{ color: "#1D3071", fontSize: "16px" }}>LandCombos Details</h2>
                                            {arrowClickLnC ? <img src="./up-arrow.svg"
                                                onClick={() => {
                                                    setArrowClickLnC(!arrowClickLnC)
                                                }}
                                            /> :
                                                <img src="./down-arrow.svg"
                                                    onClick={() => {
                                                        setArrowClickLnC(!arrowClickLnC)
                                                    }}
                                                />}

                                        </div>
                                        {landComboData.map((landCombos, id) => {
                                            return <>
                                                <div className={arrowClickLnC ? "booking-custom-details-package" : "custom-none"} >
                                                    <div className="booking-custom-details-child">
                                                        <h4>Title</h4>
                                                        <p>{landCombos.title}</p>
                                                    </div>
                                                    {/* <div className="booking-custom-details-child">
                                                    <h4>Duration</h4>
                                                    <p>3N/4D</p>
                                                </div> */}
                                                    <div className="booking-custom-details-child">
                                                        <h4>From - To</h4>
                                                        <p>( {landCombos.startDate?.split("T")[0]} ) - ( {landCombos.endDate?.split("T")[0]} )</p>
                                                    </div>
                                                    <div className="booking-custom-details-child">
                                                        <h4>Adults</h4>
                                                        <p>{landCombos.numberOfAdults}</p>
                                                    </div>
                                                    <div className="booking-custom-details-child">
                                                        <h4>Children</h4>
                                                        <p>{landCombos.numberOfChildrens}</p>
                                                    </div>
                                                    <div className="booking-custom-details-child">
                                                        <h4>Infants</h4>
                                                        <p>{landCombos.numberOfInfants}</p>
                                                    </div>
                                                    <div className="booking-custom-details-child">
                                                        <h4>Price</h4>
                                                        <p><b>AED {landCombos.cost}</b></p>
                                                    </div>
                                                </div>
                                                {((id + 1) < landComboData.length) && arrowClickLnC && (
                                                    <>
                                                        <hr />
                                                    </>
                                                )}
                                            </>
                                        })}
                                        <hr color="#ccc" />
                                    </div>
                                )}
                                {transferData && transferData.length > 0 && (
                                    <div className="booking-pkg-custom-details">
                                        <div className="booking-pkg-custom-header">
                                            <h2 style={{ color: "#1D3071", fontSize: "16px" }}>Transfer Details</h2>
                                            {arrowClickT ? <img src="./up-arrow.svg"
                                                onClick={() => {
                                                    setArrowClickT(!arrowClickT)
                                                }}
                                            /> :
                                                <img src="./down-arrow.svg"
                                                    onClick={() => {
                                                        setArrowClickT(!arrowClickT)
                                                    }}
                                                />}

                                        </div>
                                        {transferData && transferData.length > 0 && (
                                            <>
                                                {transferData.map((transfers, id) => {
                                                    return <>
                                                        <div className={arrowClickT ? "booking-custom-details-package" : "custom-none"}>
                                                            <div className="booking-custom-details-child">
                                                                <h4>Trip Type</h4>
                                                                <p>{transfers.selectedTripType}</p>
                                                            </div>
                                                            <div className="booking-custom-details-child">
                                                                <h4>Pickup</h4>
                                                                <p>{transfers?.InOut?.[0].from}</p>
                                                            </div>
                                                            <div className="booking-custom-details-child">
                                                                <h4>Pickup Date & Time</h4>
                                                                <p><b>Arrival: </b>{transfers?.InOut[0]?.pickupTimeForArrival.split("T")[0]} &nbsp;
                                                                    {transfers?.InOut[0]?.arrivalPickupTime && `(${transfers?.InOut[0]?.arrivalPickupTime})`}
                                                                </p>
                                                                {transfers.selectedTripType === "ROUND_TRIP" && (
                                                                    <p><b>Departure: </b>{transfers?.InOut[1]?.pickupTimeForDeparture?.split("T")[0]} &nbsp;
                                                                        {transfers?.InOut[1]?.departurePickupTime && `(${transfers?.InOut[1]?.departurePickupTime})`}
                                                                    </p>
                                                                )}
                                                            </div>
                                                            <div className="booking-custom-details-child">
                                                                <h4>Drop off</h4>
                                                                <p>{transfers?.InOut[0]?.to}</p>
                                                            </div>
                                                            <div className="booking-custom-details-child">
                                                                <h4>Vehicle</h4>
                                                                <p>{transfers?.InOut[0]?.vehicle?.name}</p>
                                                            </div>
                                                            <div className="booking-custom-details-child">
                                                                <h4>Passengers</h4>
                                                                <p>{parseInt(transfers.numberOfAdults ? transfers.numberOfAdults : 0) +
                                                                    parseInt(transfers.numberOfChildrens ? transfers.numberOfChildrens : 0)+ 
                                                                    parseInt(transfers.numberOfInfants ? transfers.numberOfInfants : 0)
                                                                    }
                                                                </p>
                                                            </div>
                                                            {/* <div className="booking-custom-details-child">
                                                                <h4>Adults</h4>
                                                                <p>{transfers.numberOfAdults}</p>
                                                            </div> */}
                                                            {/* <div className="booking-custom-details-child">
                                                                <h4>Children</h4>
                                                                <p>{transfers.numberOfChildrens}</p>
                                                            </div> */}
                                                            {/* <div className="booking-custom-details-child">
                                                                <h4>Infants</h4>
                                                                <p>{transfers.numberOfInfants}</p>
                                                            </div> */}
                                                            <div className="booking-custom-details-child">
                                                                <h4>Price</h4>
                                                                <p><b>AED {transfers.finalCost}</b></p>
                                                            </div>
                                                        </div>
                                                        {((id + 1) < transferData.length) && arrowClickT && (
                                                            <>
                                                                <hr />
                                                            </>
                                                        )}
                                                    </>
                                                })}
                                            </>
                                        )}
                                        <hr color="#ccc" />
                                    </div>
                                )}
                                <div className="booking-pkg-agent-details">
                                    <div className="booking-pkg-agent-name">
                                        <h4>Agent Name</h4>
                                        <p>{agentName}</p>
                                    </div>
                                    <div className="booking-pkg-agent-name">
                                        <h4>Mobile No.</h4>
                                        <p>+{agentPhone}</p>
                                    </div>
                                    <div className="booking-pkg-agent-name">
                                        <h4>Email</h4>
                                        <p>{agentEmail}</p>
                                    </div>
                                </div>
                                <hr color="#ccc" />
                                <div className="choose-paymentgatway" style={{ padding: "0 2rem" }} >
                                    <h4>Select Payment Gateway</h4>
                                    <select className="payment-select"
                                        style={{
                                        }}
                                        onChange={(e) => {
                                            setGatewayType(e.target.value)
                                        }}>
                                        <option disabled >Select Gateway</option>
                                        <option value="STRIPE"> STRIPE</option>
                                        <option value="TELR">TELR</option>
                                    </select>
                                </div>
                            </div>
                            <div>
                                <div className="booking-pkg-price-and-actions">
                                    <div className="booking-pkg-price">
                                        <h2><span>Total cost:&nbsp;AED </span><b>
                                            {totalCost}
                                        </b>
                                            {/* <sub style={{ fontSize: "12px" }}>+VAT(5%)</sub> */}
                                        </h2>
                                    </div>
                                    {/* <div className="booking-pkg-actions">
                                        {loading ? <div className="loader"></div> :
                                            <>
                                                <button id="reject"
                                                    onClick={rejectRequest}
                                                    disabled={bookingStatus === 'Confirmed'}
                                                    className={bookingStatus === 'Confirmed' ? "intensity" : ""}
                                                >Reject</button>
                                                <button id="on-hold"
                                                    onClick={onHoldRequest}
                                                    disabled={bookingStatus === 'Confirmed'}
                                                    className={bookingStatus === 'Confirmed' ? "intensity" : ""}
                                                >
                                                    Hold
                                                </button>
                                                <button id="send-link"
                                                    onClick={sendPaymentLink}
                                                    disabled={bookingStatus === 'Confirmed'}
                                                    className={bookingStatus === 'Confirmed' ? "intensity" : ""}
                                                >
                                                    {bookingStatus === 'Confirmed' ? "Sent" : "Send payment Link"}
                                                </button>
                                            </>
                                        }
                                    </div> */}
                                    <div className="booking-pkg-actions">
                                <button id="reject"
                                    onClick={rejectRequest}
                                    disabled={(bookingStatus === 'Rejected' ||
                                        bookingStatus === 'Confirmed' 
                                        // bookingStatus === 'Awaiting Payment'
                                    )}
                                    className={(bookingStatus === 'Rejected' ||
                                        bookingStatus === 'Confirmed' 
                                        // bookingStatus === 'Awaiting Payment'
                                    ) ? "intensity" : ""}
                                >Reject</button>
                                <button id="on-hold"
                                    onClick={onHoldRequest}
                                    disabled={(bookingStatus === 'On Hold' ||
                                        bookingStatus === 'Rejected' ||
                                        bookingStatus === 'Confirmed' 
                                        // bookingStatus === 'Awaiting Payment'
                                    )}
                                    className={(bookingStatus === 'On Hold' ||
                                        bookingStatus === 'Rejected' ||
                                        bookingStatus === 'Confirmed' 
                                        // bookingStatus === 'Awaiting Payment'
                                    ) ? "intensity" : ""}
                                > Hold </button>
                                
                                {loading ? <div className="loader">

                                </div> :
                                    <>
                                        {(bookingStatus === "Pending" || bookingStatus === "On Hold") &&
                                            <button id="send-link"
                                                onClick={sendPaymentLink}
                                            >
                                                Send Payment Link
                                            </button>
                                        }
                                        {bookingStatus === "Awaiting Payment" &&
                                            <button id="send-link"
                                                onClick={() => {
                                                    sendPaymentLink();
                                                    SetResendCount(count + 1);
                                                }}
                                            >
                                                Resend Payment Link
                                            </button>
                                        }
                                        {bookingStatus === "Confirmed" &&
                                            <button id="send-link"
                                                onClick={() => {
                                                    alert("Payment already received for this booking");
                                                    return;
                                                }}
                                            >
                                                Payment Received
                                            </button>
                                        }
                                        {bookingStatus === "Rejected" &&
                                            <button id="send-link"
                                                onClick={() => {
                                                    alert("This request has been rejected");
                                                    return;
                                                }}
                                            >
                                                Booking Rejected
                                            </button>
                                        }
                                    </>

                                    // <button id="send-link"
                                    //     onClick={sendPaymentLink}
                                    //     disabled={bookingStatus === 'Confirmed'}
                                    //     className={bookingStatus === 'Confirmed' ? "intensity" :""}
                                    // >
                                    //     {bookingStatus === "Pending" && "Send Payment Link"}
                                    //     {bookingStatus === "Confirmed" && "Payment Received"}
                                    //     {bookingStatus === "Awaiting Payment" && "Resend"}
                                    //     {bookingStatus === "Rejected" && "Send Payment Link"}
                                    //     {bookingStatus === "On Hold" && "Send Payment Link"}
                                    // </button> 
                                }
                            </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
        {rejection && (
            <>
                <div className="transfer-reason-parent">
                    <div className="transfer-raeson">
                        <div className="reason-topbar">
                            <p>Reason for Rejection</p>
                            <p
                                id="cencel"
                                onClick={reasonClose}
                            >
                                <IoMdClose />
                            </p>
                        </div>
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            rejectBooking();
                        }}>
                            <div className="reason-details">
                                <textarea
                                    placeholder="Write Reason..."
                                    value={rejectionText}
                                    onChange={(e) => {
                                        setRejectionText(e.target.value);
                                    }}
                                    required
                                />
                            </div>
                            <div className="reason-button">
                                <button type="submit">Reject</button>
                            </div>
                        </form>
                    </div>
                </div>
            </>
        )}
        {onhold && (
            <>
                <div className="transfer-reason-parent">
                    <div className="transfer-raeson">
                        <div className="reason-topbar">
                            <p>Reason for On Hold</p>
                            <p
                                id="cencel"
                                onClick={reasonClose}
                            >
                                <IoMdClose />
                            </p>
                        </div>
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            onHoldBooking();
                        }}>
                            <div className="reason-details">
                                <textarea
                                    placeholder="Write Reason..."
                                    value={onholdText}
                                    onChange={(e) => {
                                        setOnholdText(e.target.value);
                                    }}
                                    required
                                />
                            </div>
                            <div className="reason-button">
                                <button>On Hold</button>
                            </div>
                        </form>
                    </div>
                </div>
            </>
        )}
    </>
}
export default BookCustomDetails;
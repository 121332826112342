import * as React from "react";
import { styled } from "@mui/material/styles";
import TableContainer from "@mui/material/TableContainer";
import { IoIosSearch } from "react-icons/io";
import './Packagelist.css';

import {
  Stack,
  Pagination,
  Paper,
} from "@mui/material";
import { APIPath, token } from "../../config/api";
import { Link } from "react-router-dom";
import PackageDetails from "./PackageDetail";
import CreatePackageForm from "./CreatePackageForm";

const StyledTableContainer = styled(TableContainer)(({ theme, open }) => ({
  marginTop: "1%",
  marginLeft: open ? theme.spacing(29) : theme.spacing(7),
  width: open ? "80%" : "94%",
  transition: "margin-left 0.3s ease",
  background: "rgba(204,204,204,0.2)",
  padding: "1rem 1rem 1rem 1rem",
  boxShadow: "none",
}));

const StyledPaginationContainer = styled("div")({
  background: "#fff",
  margin: "2rem auto 0 auto;",
  zIndex: 1,
  background: "none",
  display: "flex",
  justifyContent: "center"
});

const PackagesList = ({ open }) => {
  document.body.style.overflow = 'auto';
  const [packages, setPackages] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [perPage] = React.useState(10);
  const [searchText, setsearchText] = React.useState('');
  const [packageForm, setPackageForm] = React.useState(false);

  const loadPackagesList = () => {
    fetch(`${APIPath}/api/v1/agency/packages`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: 'GET',
      mode: "cors",
    })
      .then((res) => res.json())
      .then((data) => {
        setPackages(data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        alert('Error fetching Package list:', error);
        setIsLoading(false);
      });
  }
  React.useEffect(() => {
    loadPackagesList();
  }, [])
  const indexOfLastItem = currentPage * perPage;
  const indexOfFirstItem = indexOfLastItem - perPage;
  const currentItems = packages && packages.slice(indexOfFirstItem, indexOfLastItem);

  const filterdata = currentItems?.filter(item =>
    item.title.toLowerCase().includes(searchText.toLowerCase())
  );

  const [clickedPKGId, setClickedPKGId] = React.useState('');
  const [packageOpen, setPackageOpen] = React.useState(false);

  const viewMore = (id) => {
    setClickedPKGId(id)
    setPackageOpen(true)

  }
  const closeMore = () => {
    setPackageOpen(false)
    setPackageForm(false);
  }
  const Uppercase = (str) => str?.toUpperCase();
  const capitalize = (str) => {
    if (typeof str !== 'string') return '';
    return str?.charAt(0).toUpperCase() + str?.slice(1).toLowerCase();
  };
  //-------------------------------- Package active or Inactive ------------------------------------------
  const [packageActive, setPackageActive] = React.useState(true)
  const handleActiveAndInactive = (id) => {
    setPackageActive((prev) => !prev);
    console.log(`Status of ${id} is ${packageActive}`);
  }

  return <StyledTableContainer component={Paper} open={open}>
    <div className="package-header">
      <h2>Packages</h2>
      <button
        onClick={() => {
          setPackageForm(true);
        }}
      >Add New Package</button>
    </div>
    <div className="package-search-table">
      <div className="package-search">
        <input type="text" placeholder="Search.." value={searchText} onChange={(e) => {
          setsearchText(e.target.value)
        }} />
        <IoIosSearch style={{ cursor: "pointer", color: "skyblue" }} />
      </div>
      <div className="package-table">
        {isLoading ? (
          <div className="loader"></div>
        ) : (<>
          <table>
            <thead>
              <tr>
                <th>Images</th>
                <th>Region</th>
                <th>Title</th>
                <th>Duration</th>
                <th>Category</th>
                <th>Price</th>
                {/* <th>Status</th> */}
                <th>Actions</th>
              </tr>
            </thead>
            {filterdata?.length > 0 ? (<>
              {filterdata?.map((val, id) => {
                return <>
                  <tbody>
                    <tr>
                      <td className="package-image"><img src={val.thumbnailImage} /></td>
                      <td><p><b>{Uppercase(val.location?.country)},</b><b>{capitalize(val.location?.city)}</b></p></td>
                      <td>{val.title}</td>
                      <td>{val.duration}</td>
                      <td>{val.categoryName}</td>
                      <td>AED {val?.price?.[0].price}</td>
                      {/* <td id="active-input">
                        <input type="checkbox"
                          checked={val.status}
                          value={val.status}
                          onChange={(e) => {
                            console.log("this check is clicked");
                            handleActiveAndInactive(val._id);
                          }}
                        />
                        <div id="active-inactive">
                          <p >{val.status ? "Active" : "Inactive"}</p>
                        </div>
                      </td> */}
                      <td><Link to="#" onClick={() => {
                        viewMore(val._id)
                      }}> view more </Link></td>
                    </tr>
                  </tbody>
                </>
              })}
            </>) : (<div>
              <h2>Data not found</h2>
            </div>)}
          </table>
        </>)
        }
      </div>
    </div>
    <StyledPaginationContainer>
      <Stack spacing={1}>
        <Pagination
          count={Math.ceil((packages && packages.length) / perPage)}
          // count={1}
          page={currentPage}
          onChange={(event, value) => setCurrentPage(value)}
          variant="outlined"
          color="primary"
          boundaryCount={1}
        />
      </Stack>
    </StyledPaginationContainer>
    {packageOpen && <PackageDetails close={closeMore} pkgId={clickedPKGId} loadPackagesList={loadPackagesList} />}
    {packageForm && <CreatePackageForm close={closeMore} loadPackagesList={loadPackagesList} />}
  </StyledTableContainer>

};

export default PackagesList;

import * as React from "react";
import { styled } from "@mui/material/styles";
import TableContainer from "@mui/material/TableContainer";
import { Paper } from "@mui/material";
import { APIPath, token } from "../../config/api";
import CreatePackageForm from "./CreateLandComboForm";

const StyledTableContainer = styled(TableContainer)(({ theme, open }) => ({
    width: open ? "80%" : "94%",
    transition: "width 0.3s ease",
    paddingTop: "7px",
    marginLeft: open ? "243px" : "69px",
    position: "relative",
    borderRadius: "0px",
    overflow: "hidden",
    marginBottom: "20px",
    boxShadow: "none"
}));


const PackageDetail = ({ open, close, pkgId, loadPackagesList }) => {
    document.body.style.overflow = 'hidden';
    const [packageData, setPackageData] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(true);
    const [editableLandCombos, setEditableLandCombos] = React.useState(false);

    const loadPackagesDetails = () => {
        fetch(`${APIPath}/api/v1/agency/land_combos?id=${pkgId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            method: 'GET',
            mode: "cors",
        })
            .then((res) => res.json())
            .then((data) => {
                setPackageData(data.data);
                setIsLoading(false);
            })
            .catch((error) => {
                alert('Error fetching  LandCombos Details:', error);
                setIsLoading(false);
            });
    }
    React.useEffect(() => {
        loadPackagesDetails();
    }, []);
    const DeletePackage = () => {
        let confirm = window.confirm("Are you sure you want to delete this LandCombos?");
        if (confirm) {
            fetch(`${APIPath}/api/v1/agency/land_combos?id=${pkgId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                method: 'DELETE',
                mode: "cors",
            })
                .then((res) => res.json())
                .then((data) => {
                    alert(data.message);
                    close();
                    loadPackagesList();
                })
                .catch((error) => {
                    alert('Error while deleting LandCombos...', error);
                });
        } else {
            return
        }
    }
    const onClose = () => {
        setEditableLandCombos(false);
        close();
    }
    return <>
        <StyledTableContainer component={Paper} open={open}>
            <div className="admin-package-detail-container">
                <div className="admin-package-detail-page">
                    {isLoading ? (
                        <div className="loader"></div>
                    ) : (<>
                        <div className="back-and-text">
                            <div className="back">
                                <img src="backicon.svg" alt="back-icon" onClick={close} />
                            </div>
                            <div className="text">
                                <h2>Land Combos Details</h2>
                            </div>
                        </div>
                        <div className="package-basic-details">
                            <h3>Basic Details</h3>
                            <div className="admin-pkg-title-description">
                                <div className="title">
                                    <h4>Title:</h4>
                                    <h5>{packageData[0].title}</h5>
                                </div>
                                {/* <div className="title-value">
                                    <h5>{packageData[0].title}</h5>
                                </div> */}
                                <div className="title">
                                    <h4>Description:</h4>
                                    <h5>{packageData[0].landComboOverview}</h5>
                                </div>
                                {/* <div className="description-value">
                                    <h5>{packageData[0].landComboOverview}</h5>
                                </div> */}
                            </div>
                            <div className="admin-pkg-title-description-pic">
                                <div className="title">
                                    <h4>Cover Photo</h4>
                                </div>
                                <div className="title-value">
                                    <img src={packageData[0].bannerImage} height="100px" width="150px" />
                                </div>
                                <div className="description">
                                    <h4>Banner Photo</h4>
                                </div>
                                <div className="description-value">
                                    <img src={packageData[0].thumbnailImage} height="100px" width="150px" />
                                </div>
                            </div>
                        </div>
                        <div className="package-basic-details">
                            <h3>Location Details</h3>
                            <div className="location-details">
                                <div className="title">
                                    <h4>Country:</h4>
                                </div>
                                <div className="title-value">
                                    {packageData[0]?.location?.country ? <h5>{packageData[0]?.location?.country}</h5> :
                                        <h5>UAE</h5>}
                                </div>
                                <div className="description">
                                    <h4>City</h4>
                                </div>
                                <div className="description-value">
                                    {packageData[0]?.location?.city ? <h5>{packageData[0]?.location?.city}</h5> :
                                        <h5>Dubai</h5>}
                                </div>
                            </div>
                        </div>

                        <div className="package-form-inclusion-exclusion">
                            <h2>Cancellation Policy</h2>
                            <div className="package-form-cancellation-polocy">
                                {packageData[0]?.cancellationRefundPolicy?.map((val, id) => {
                                    return <ul key={id}>
                                       {val && <li>{val}</li>} 
                                    </ul>
                                })}
                            </div>
                        </div>
                        <div className="package-basic-details">
                            <h3>Inclusions/Exclusions</h3>
                            <div className="location-details">
                                <div className="title">
                                    <h4>Inclusions:</h4>
                                </div>
                                <div className="title-value">
                                    {packageData[0]?.includedServices.map((val, id) => {
                                        return <>
                                            <ol>
                                                <p>{val}</p>
                                            </ol>
                                        </>
                                    })}
                                </div>
                                <div className="description">
                                    <h4>Exclusions:</h4>
                                </div>
                                <div className="description-value">
                                    {packageData[0]?.excludedServices.map((val, id) => {
                                        return <>
                                            <ol>
                                                <p>{val}</p>
                                            </ol>
                                        </>
                                    })}
                                </div>

                            </div>
                        </div>
                        <div className="package-basic-details">
                            <h3>Meeting Point</h3>
                            <div>
                                {packageData[0].expectations?.map((val, id) => {
                                    return <div style={{borderBottom:"2px solid #dedede",paddingTop:"10px"}}>
                                        <p><b>Location:</b> {val.location}</p>
                                        <p><b>Description:</b> {val.description}</p>
                                    </div>
                                })}
                            </div>
                        </div>
                        <div className="package-basic-details">
                            <h3>Notes:-</h3>
                            <p>{packageData[0].note}</p>
                        </div>

                        <div className="package-price">
                            <h3>Pricing</h3>
                            <div>
                                <p>Adult Price</p>
                                <h4>AED {packageData[0]?.price[0]?.adultPrice}</h4>
                            </div>
                            <div>
                                <p>Child Price</p>
                                <h4>AED {packageData[0]?.price[0]?.childPrice}</h4>
                            </div>
                        </div>
                        <div className="package-delete-edit-btn">
                            <button id="delete"
                                onClick={() => {
                                    DeletePackage();
                                }}
                            >
                                <img src="delete1.svg" />
                                Delete
                            </button>
                            <button id="edit"
                                onClick={() => {
                                    setEditableLandCombos(true)
                                }}
                            >
                                <img src="editicon.svg" />
                                Edit
                            </button>
                        </div>
                    </>)}
                </div>
            </div>
        </StyledTableContainer>
        {editableLandCombos && <CreatePackageForm close={onClose}
            editedLandCombosId={packageData[0]?._id}
            tilteP={packageData[0]?.title}
            descriptionP={packageData[0]?.landComboOverview}
            bannerImageP={packageData[0]?.bannerImage}
            coverImageP={packageData[0]?.thumbnailImage}
            countryP={packageData[0].location.countryId}
            fromCountryNameP={packageData[0].location.country}
            cityP={packageData[0].location.cityId}
            cityNameP={packageData[0].location.city}
            cancellationRefundPolicyP={packageData[0]?.cancellationRefundPolicy}
            inclusionP={packageData[0]?.includedServices}
            exclusionP={packageData[0]?.excludedServices}
            exceptionsP={packageData[0].expectations}
            noteP={packageData[0].note}
            adultsPriceP={packageData[0]?.price[0].adultPrice}
            childpriceP={packageData[0]?.price[0].childPrice}
            loadPackagesList={loadPackagesList}
            pkgId={pkgId}
        />}
    </>
};

export default PackageDetail;

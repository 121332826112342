import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/system";
import { IoMdClose } from "react-icons/io";
import { APIPath, token } from "../../config/api";

const StyledDashboardContainer = styled(Grid)(({ theme, open }) => ({
    background: "rgba(204,204,204,0.2)",
    marginTop: "1%",
    marginLeft: open ? theme.spacing(30) : theme.spacing(8),
    width: open ? "80%" : "94%",
    transition: "margin-left 0.3s ease",
    height: "100vh",
}));

const TransferDetails = ({ close, open, transferId, transferDetails, loadTransferList }) => {

    document.body.style.overflow = 'hidden';
    const [isLoading, setIsLoading] = useState(false);

    const deleteAgent = () => {
        setIsLoading(true)
        let confirm = window.confirm("Are you sure to delete this transfer? ")
        if (confirm) {
            fetch(`${APIPath}/api/v1/agency/transfers?id=${transferId} `, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                method: 'DELETE',
                mode: "cors",
            })
                .then((res) => res.json())
                .then((data) => {
                    setIsLoading(false)
                    alert("transfer deleted");
                    close();
                    loadTransferList();
                })
                .catch((error) => {
                    alert('Error fetching Transfer list:', error);
                    setIsLoading(false)
                });
        }
        else {
            console.log("Transfer not deleted");
            return
        }
    }

    return <StyledDashboardContainer open={open}>
        <div className="booking-details-container">
            <div className="booking-details-page">
                <div className="booking-pkg-pass-header">
                    <h4>Transfer Details</h4>
                    <h2 onClick={close}><IoMdClose /></h2>
                </div>
                <div className="transfer-detail-region">
                    <h2>Region Info</h2>
                    <div className="transfer-detail-region-value">
                        <div className="transfer-detail-region-info">
                            <h4>Country:</h4>
                            <p>{transferDetails?.countryName}</p>
                        </div>
                        <div className="transfer-detail-region-info">
                            <h4>City:</h4>
                            <p>{transferDetails?.cityName}</p>
                        </div>
                        <div className="transfer-detail-region-info">
                            <h4>Service Type:</h4>
                            <p>{transferDetails?.type}</p>
                        </div>
                        <div className="transfer-detail-region-info">
                            <h4>Transfer Type:</h4>
                            <p>{transferDetails?.transferType}</p>
                        </div>
                    </div>
                    <div className="transfer-detail-region-value">
                        <div className="transfer-detail-region-info">
                            <h4>Pick Up:</h4>
                            <p>{transferDetails?.pickup}</p>
                        </div>
                        <div className="transfer-detail-region-info">
                            <h4>Drop Off:</h4>
                            <p>{transferDetails?.dropoff}</p>
                        </div>
                    </div>
                </div>
                <div className="transfer-detail-region">
                    <h2>Vehicle Info</h2>
                    <div className="transfer-detail-region-value">
                        <div className="transfer-detail-region-info">
                            <h4>Image: &nbsp;</h4>
                            <p><img src={transferDetails?.vehicle?.images[0]} height={100} width={100} alt="Vehicle" /></p>
                        </div>
                        <div className="transfer-detail-region-info">
                            <h4>Name:</h4>
                            <p>{transferDetails?.vehicle?.name}</p>
                        </div>
                        <div className="transfer-detail-region-info">
                            <h4>Max. Passengers Allowed:</h4>
                            <p>{transferDetails?.vehicle?.maxPassenger}</p>
                        </div>
                    </div>
                    <div className="transfer-detail-region-value">
                        <div className="transfer-detail-region-info" style={{width:'70%'}}>
                            <h4>Additional Info:</h4>
                            <p>{transferDetails?.additionalDetails}</p>
                        </div>
                        <div className="transfer-detail-region-info">
                            <h4>Cost:</h4>
                            <p><b>AED {transferDetails?.cost}</b></p>
                        </div>
                    </div>
                </div>
                {isLoading ? (<div className="loader"></div>) : (
                    <div className="agent-delete-btn">
                        <button
                            onClick={() => {
                                deleteAgent();
                            }}
                        >
                            <img src="deleteicon.svg" alt="." />
                            Delete
                        </button>
                    </div>
                )}
            </div>
        </div>
    </StyledDashboardContainer>
};

export default TransferDetails;


import * as React from "react";
import { styled } from "@mui/material/styles";
import TableContainer from "@mui/material/TableContainer";
import { Paper, } from "@mui/material";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useState } from "react";
import './Vehicle.css';
import { APIPath, token } from "../../config/api";


const StyledTableContainer = styled(TableContainer)(({ theme, open }) => ({
    width: open ? "80%" : "94%",
    transition: "width 0.3s ease",
    paddingTop: "7px",
    marginLeft: open ? "243px" : "69px",
    position: "relative",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    overflow: "hidden",
    marginBottom: "20px",
    boxShadow: "none",
}));

const Vehicle = ({ open, onClose }) => {

    const [jsonData, setJsonData] = useState({
        name: "",
        maxPassenger: "",
        handbagAllowed: "",
        suitCaseAllowed: "",
        vehicleType: "",
        vehicleProviderName: "",
        vehicleProviderEmail: "",
        vehicleProviderId: "",
        vehicleProviderMobile: ""
    });
    const [isLoading, setIsLoading] = useState(false);
    const handleJsonInputChange = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/^\s+|\s+(?=\s)/g, '');
        setJsonData(prevData => ({
            ...prevData,
            [name]: sanitizedValue
        }));
    };


    const [image, setImage] = useState(null);
    const handleImageChange = (e) => {
        setImage(e.target.files[0]);
    };
    const handlePhoneInputChange = (value) => {
        setJsonData(prevData => ({
            ...prevData,
            vehicleProviderMobile: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        sendDataToServer(jsonData, image);
    };
    const sendDataToServer = async (jsonData, image) => {
        try {
            showLoader();
            const formData = new FormData();
            formData.append('jsonData', JSON.stringify(jsonData));
            formData.append('image', image);
            const response = await fetch(`${APIPath}/api/v1/agency/vehicles`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                method: 'POST',
                mode: "cors",
                body: formData
            });
            hideLoader();
            if (!response.ok) {
                console.log(response)
            }
            alert("New vehicle added");
            const responseData = await response.json();
            onClose();
        } catch (error) {
            console.error('Error sending data to server:', error);
        }
    };

    const showLoader = () => {
        setIsLoading(true)
    };
    const hideLoader = () => {
        setIsLoading(false)
    };

    return (
        <StyledTableContainer component={Paper} open={open}>
            <div className="add-vehicle-form-container">
                <form onSubmit={handleSubmit}
                    className="vehicle-add-form">
                    <h2>Add Vehicle</h2>
                    <div className="vehicle-name-maxpass">
                        <div className="vehicle-name">
                            <label>Vehicle Name *</label>
                            <input
                                type="text"
                                placeholder="Type vehicle name..."
                                name="name"
                                value={jsonData.name}
                                onChange={handleJsonInputChange}
                                required
                            />
                        </div>
                        <div className="vehicle-name">
                            <label>Passenger Allowed *</label>
                            <input
                                type="number"
                                min={1} max={50}
                                placeholder="Maximum passengers allowed"
                                name="maxPassenger"
                                value={jsonData.maxPassenger}
                                onChange={handleJsonInputChange}
                                required
                            />
                        </div>

                    </div>

                    <div className="vehicle-name-maxpass">
                        <div className="vehicle-name">
                            <label>Handbag Allowed</label>
                            <input
                                type="number"
                                min={1} max={50}
                                placeholder="Maximum handbag allowed"
                                name="handbagAllowed"
                                value={jsonData.handbagAllowed}
                                onChange={handleJsonInputChange}
                                required
                            />
                        </div>
                        <div className="vehicle-name">
                            <label>Suitcase Allowed</label>
                            <input
                                type="number"
                                min={0} max={50}
                                placeholder="Maximum suitcase allowed"
                                name="suitCaseAllowed"
                                value={jsonData.suitCaseAllowed}
                                onChange={handleJsonInputChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="vehicle-name-maxpass">
                        <div className="vehicle-name">
                            <label> Choose Image of vehicle *</label>
                            <input className="file-input"
                                placeholder="Choose image"
                                type="file"
                                accept="image/*"
                                onChange={handleImageChange}
                                required
                            />
                        </div>
                        <div className="vehicle-name">
                            <label> Select Vehicle Type</label>
                            <select value={jsonData.vehicleType} required
                                onChange={handleJsonInputChange}
                                name="vehicleType">
                                <option value="" disabled>Vehicle Type</option>
                                <option value="Private">Private</option>
                                <option value="Shared" >Shared</option>
                            </select>
                        </div>
                    </div>

                    <div className="vehicle-name-maxpass">
                        <div className="vehicle-name">
                            <label>Vehicle provider Name(optional)</label>
                            <input
                                type="text"
                                placeholder="Enter Provider name"
                                name="vehicleProviderName"
                                value={jsonData.vehicleProviderName}
                                onChange={handleJsonInputChange}
                            // required
                            />
                        </div>
                        <div className="vehicle-name">
                            <label>Vehicle provider Email(optional)</label>
                            <input
                                type="email"
                                placeholder="Enter Provider email"
                                name="vehicleProviderEmail"
                                value={jsonData.vehicleProviderEmail}
                                onChange={handleJsonInputChange}
                            // required
                            />
                        </div>
                    </div>
                    <div className="vehicle-name-maxpass">
                        <div className="vehicle-name">
                            <label>Vehicle provider Mobile(optional)</label>
                            <PhoneInput
                                international
                                country={'in'}
                                value={jsonData.vehicleProviderMobile}
                                onChange={handlePhoneInputChange}
                                inputProps={{
                                    required: true,
                                    autoFocus: true,
                                }}
                            />
                            {/* {moberr && (<div>
                                <p style={{color:"red"}}>Please check this field</p>
                            </div>)} */}
                        </div>
                        <div className="vehicle-name">
                            <label>Vehicle provider Id(optional)</label>
                            <input
                                type="text"
                                placeholder="Enter Provider Id"
                                name="vehicleProviderId"
                                value={jsonData.vehicleProviderId}
                                onChange={handleJsonInputChange}
                            // required
                            />
                        </div>
                    </div>
                    <div className="vehicle-add-button">
                        {isLoading ? (
                            <div className="loader">

                            </div>
                        ) : (
                            <>

                                <button onClick={onClose} id="cancel">Cancel</button>
                                <button type="submit" id="addvehicle" >Add Vehicle</button>
                            </>
                        )}
                    </div>
                </form>
            </div>
        </StyledTableContainer>

    );
}

export default Vehicle;
import React, { useState ,useEffect} from "react";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/system";
import { PackagegraphAnnually, PackagegraphWeekly, PackagegraphMonthly } from "./Packagegraph";
import { AttractiongraphAnnually, AttractiongraphMonthly, AttractiongraphhWeekly } from "./Attractiongraph";
import { LandcombographAnnually, LandcombographMonthly, LandcombographhWeekly } from "./Landcombograph";
import { TransfergraphAnnually,TransfergraphMonthly,TransfergraphWeekly } from "./Transfergraph";
import './Revenue.css';
import { APIPath,token } from "../../config/api";

const StyledDashboardContainer = styled(Grid)(({ theme, open }) => ({
  background: "rgba(204,204,204,0.2)",
  // paddingTop: "10px",
  marginTop: "1%",
  marginLeft: open ? theme.spacing(30) : theme.spacing(8),
  width: open ? "80%" : "94%",
  transition: "margin-left 0.3s ease",
}));

const Revenue = ({ open }) => {
  const [transferRevenue,setTransferRevenue]=useState();
  const [packageRevenue,setPackageRevenue]=useState();
  const [attractionRevenue,setAttractionRevenue]=useState();
  const [landcombosRevenue,setLandcombosRevenue]=useState();
  const [customRevenue,setCustomRevenue]=useState();
  const [annually, setAnnually] = useState(true);
  const [monthly, setMonthly] = useState(false);
  const [weekly, setWeekly] = useState(false);
  const [graphValue, setGraphValue] = useState('package');

  const onGraphChange = (e) => {
    setGraphValue(e.target.value);
  }
  useEffect(() => {
    fetch(`${APIPath}/api/v1/agency/revenue`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      method: 'GET',
      mode: 'cors'
    }).then((res) => res.json())
      .then((data) => {
        console.log(data.data)
        setPackageRevenue(data.data[0].packageReven);
        setAttractionRevenue(data.data[0].attractionReven);
        setLandcombosRevenue(data.data[0].landComboReven);
        setTransferRevenue(data.data[0].transferReven);
        setCustomRevenue(data.data[0].customReven);
      }).catch((err) => {
        console.log(err);
      })
  }, [])

  return (
    <>
      <StyledDashboardContainer open={open}>
        <div className="dashboard-container">
          <h2>Revenue</h2>
          <div className="revenue-all-details">
            <div className="dashboard-agent-parent revenue">
              <div className="revenue-agent">
                <div>
                  <img src="revenueTotalIcon.svg" alt="Total Revenue" />
                </div>
                <div className="dashboard-agent-detail">
                  <h4>Total</h4>
                  <h2>AED {transferRevenue + packageRevenue + landcombosRevenue + attractionRevenue + customRevenue}</h2>
                </div>
              </div>
              {/* <p className="dashboard-inc-text"><img src="/incrementgraph.svg" /><span style={{ color: "rgba(0, 182, 155, 1)" }}>8.5%</span>up from yesterday</p> */}
            </div>
            <div className="dashboard-agent-parent revenue">
              <div className="revenue-agent">
                <div>
                  <img src="transferpendingicon.svg" height={56} width={56} alt="Transfer Revenue"/>
                </div>
                <div className="dashboard-agent-detail revenue">
                  <h4>Transfers</h4>
                  <h2>AED {transferRevenue}</h2>
                </div>
              </div>
              {/* <p className="dashboard-inc-text"><img src="/incrementgraph.svg" /><span style={{ color: "rgba(0, 182, 155, 1)" }}>8.5%</span>up from yesterday</p> */}
            </div>
            <div className="dashboard-agent-parent revenue">
              <div className="revenue-agent">
                <div>
                  <img src="revenueLandComboIcon.svg" alt="Land Combos Revenue"/>
                </div>
                <div className="dashboard-agent-detail revenue">
                  <h4>Land Combos</h4>
                  <h2>AED {landcombosRevenue}</h2>
                </div>
              </div>
              {/* <p className="dashboard-inc-text"><img src="/incrementgraph.svg" /><span style={{ color: "rgba(0, 182, 155, 1)" }}>8.5%</span>up from yesterday</p> */}
            </div>
            <div className="dashboard-agent-parent revenue">
              <div className="revenue-agent">
                <div>
                  <img src="revenueAttractionIcon.svg" alt="Attraction Revenue"/>
                </div>
                <div className="dashboard-agent-detail revenue">
                  <h4>Attractions</h4>
                  <h2>AED {attractionRevenue}</h2>
                </div>
              </div>
              {/* <p className="dashboard-inc-text"><img src="/incrementgraph.svg" /><span style={{ color: "rgba(0, 182, 155, 1)" }}>8.5%</span>up from yesterday</p> */}
            </div>
            <div className="dashboard-agent-parent revenue">
              <div className="revenue-agent">
                <div>
                  <img src="revenuePackageIcon.svg" alt="Package Revenue"/>
                </div>
                <div className="dashboard-agent-detail revenue">
                  <h4>Packages</h4>
                  <h2>AED {packageRevenue}</h2>
                </div>
              </div>
              {/* <p className="dashboard-inc-text"><img src="/incrementgraph.svg" /><span style={{ color: "rgba(0, 182, 155, 1)" }}>8.5%</span>up from yesterday</p> */}
            </div>
           

          </div>
          <div className="dashboard-graph-recent-booking" style={{display:"block"}}>
            <div className="dashboard-graph">
              <div className="dashboard-graph-header">
                <h2>Sales Analytics</h2>
                <select onChange={(e) => onGraphChange(e)}>
                  <option value="package">All</option>
                  <option value="package">Package</option>
                  <option value="attraction">Attraction</option>
                  <option value="landcombo">Land Combo</option>
                  <option value="transfer">Transfer</option>
                </select>
              </div>
              <div className="dashboard-graph-a-m-w">
                <p className={annually ? "active" : ""}
                  onClick={() => {
                    setAnnually(true)
                    setMonthly(false)
                    setWeekly(false)
                  }}
                >Annually</p>
                <p
                  className={monthly ? "active" : ""}
                  onClick={() => {
                    setAnnually(false)
                    setMonthly(true)
                    setWeekly(false)
                  }}
                >Monthly</p>
                <p
                  className={weekly ? "active" : ""}
                  onClick={() => {
                    setAnnually(false)
                    setMonthly(false)
                    setWeekly(true)
                  }}
                >Weekly</p>
              </div>
              <div className="dashboard-graph-container">
                {(graphValue === 'package' && annually) && <PackagegraphAnnually open={open} />}
                {(graphValue === 'package' && monthly) && <PackagegraphMonthly open={open} />}
                {(graphValue === 'package' && weekly) && <PackagegraphWeekly open={open} />}
                {(graphValue === 'attraction' && annually) && <AttractiongraphAnnually open={open}/>}
                {(graphValue === 'attraction' && monthly) && <AttractiongraphMonthly open={open}/>}
                {(graphValue === 'attraction' && weekly) && <AttractiongraphhWeekly open={open}/>}
                {(graphValue === 'landcombo' && annually) && <LandcombographAnnually open={open}/>}
                {(graphValue === 'landcombo' && monthly) && <LandcombographMonthly open={open}/>}
                {(graphValue === 'landcombo' && weekly) && <LandcombographhWeekly open={open}/>}
                {(graphValue === 'transfer' && annually) && <TransfergraphAnnually open={open} />}
                {(graphValue === 'transfer' && monthly) && <TransfergraphMonthly open={open}/>}
                {(graphValue === 'transfer' && weekly) && <TransfergraphWeekly open={open}/>}
              </div>
            </div>
          </div>

        </div>
      </StyledDashboardContainer>
    </>
  );
};

export default Revenue;

import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";

import { useDrawer } from "../../context/DrawerProvider";

import MVLogo from "../../assets/logo/MV_LOGO.svg";
import Header from "./Header"; 
import { FaArrowRightToCity } from "react-icons/fa6";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const SidebarContainer = styled(Box)(({ theme, open }) => ({
  // backgroundColor: theme.palette.primary.main,
  backgroundColor: "#74B9FF",
  // color: theme.palette.common.white,
  color: "#74B9FF",
  display: "flex",
  flexDirection: "column",
  height: "100%",
  marginLeft: open ? "243px" : "69px",
  width: open ? "80%" : "94%",
  marginTop: "8px",
  borderRadius: "5px",
}));

const SidebarHeading = styled(Typography)(({ theme }) => ({
  fontSize: "1.5rem",
  fontWeight: "bold",
  padding: theme.spacing(2),
}));

export default function MiniDrawer({ open, setOpen }) {
  // console.log("reached in min drawer");
  const [selectedHeading, setSelectedHeading] = React.useState("Dashboard");
  const theme = useTheme();
  const [open1, setOpen1] = React.useState(false); // For "Payments"
  const [openMore, setOpenMore] = React.useState(false); // For "More"

  const [sidebarText,setSidebarText]=React.useState('Dashboard');
  const [childSidebarText,setChildSidebarText]=React.useState('');

  // const handleClick = (e) => {
  //   setSelectedHeading(e);
  //   if (e === "Payments") {
  //     setOpen1(!open1);
  //   }
  // };
  const handleClick = (e) => {
    setSelectedHeading(e);
    if (e === "Payments") {
      setOpen1(!open1);
      setOpenMore(false); // Collapse "More" submenu
    } else if (e === "More") {
      setOpenMore(!openMore);
      setOpen1(false); // Collapse "Payments" submenu
    } else {
      setOpen1(false); // Collapse "Payments" submenu
      setOpenMore(false); // Collapse "More" submenu
    }
  };
  

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerOpenClose = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  return (
    <SidebarContainer open={open}>
      <Header />
      <CssBaseline />
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <img
            src={MVLogo}
            alt="Logo"
            style={{ height: 50, 
              // marginRight: 30 
            }}
          />
          <IconButton onClick={handleDrawerOpenClose} style={{marginLeft:"10px"}}>
            {!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <List>
          {[
            { text: "Dashboard", icon: <img src="Dashboard1.svg" width="25px" />, path: "/dashboard" },
            { text: "Agents", icon: <img src="agenticonside.svg"  />, path: "/agent" },
            {text:"Markup",icon:<img src="markup.svg" />, path:"markup"},
            { text: "Bookings", icon: <img src="bookingiconsidebar.svg"  />, path: "/bookings" },
            {text:"Revenue",icon:<img src="revenue.svg"/>,path:"/revenue"},
            { text: "Packages", icon: <img src="packagesicon.svg" />, path: "/packages" },
            { text: "Attractions", icon: <img src="attractionsicon.svg" />, path: "/attractions" },
            { text: "Land combos", icon: <img src="landcombosicon.svg" />, path: "/land-combos" },
            { text: "Transfers", icon: <img src="transfersicon.svg"  />, path: "/transfer" },
            { text: "Vehicle", icon: <img src="VehicleIcon.svg" />, path: "/vehicle" },
            { text:"Country", icon:<img src="country.svg" />, path:"/country"},
            { text:"City", icon:<img src="city.svg" />, path:"/city"},
            {text:"Airport",icon:<img src="airport.svg"/>, path:"/airport"},
            {text:"Hotel",icon:<img src="hotel.svg"/>, path:"/hotel"},
            {text:"Zone",icon:<img src="zone.svg"/>,path:"/zone"},
            {
              text: "Payments",
              icon: <img src="paymenticon.svg" />,
              path: "/payments",
              submenus: [
                {
                  text: "Payment List",
                  path: "/payment-list",
                  icon: <img src="paymenticon.svg" />,
                },
                {
                  text: "Payment Link",
                  path: "/payment-link",
                  icon: <img src="paymenticon.svg" />,
                },
              ],
            },
            {
              text:"More",
              icon:<img src="moreicon.svg"/>,
              path:"/articles",
              submenus:[
                {text: "Articles", icon: <img src="articleicon.svg" />, path: "/articles"},
                {text: "Shopping Stops",icon: <img src="shpooingicon.svg" />,path: "/shopping-stops",},
                {text: "Delicacies", icon: <img src="delicousicon.svg" />, path: "/delicacies"},
                {text: "Categories", icon: <img src="categoriesicon.svg" />, path: "/categories"},
                {text: "Reviews", icon: <img src="reviewicon.svg" />, path: "/reviews"},
                {text: "Subscribers",icon: <img src="subscribeicon.svg" />,path: "/subscribers",}
              ]
            },
          ].map((item, index) => (
            <ListItem
              key={item.text}
              disablePadding
              onClick={()=>{
                if(item.submenus){
                  setSidebarText('')
                }
                else{
                  setSidebarText(item.text);
                }
              }}
              
              sx={{
                display: "block",
                background: (sidebarText === item.text) ? "rgb(135, 206, 235,0.5)" : "white",
                color:(sidebarText === item.text) ? "rgba(13, 138, 205, 1)" : "black",
              }}
              
            >
              <Link
                to={item.path}
                style={{ textDecoration: "none", color: "inherit",
              }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  onClick={() => handleClick(item.text)}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.text}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                  {item.submenus &&
                    item.submenus.length > 0 &&
                    (open1 ? <ExpandLess /> : <ExpandMore />)}
                </ListItemButton>
              </Link>
              {item.submenus && item.submenus.length > 0 && (
                // <Collapse in={open1} timeout="auto" unmountOnExit>
                  <Collapse in={(open1 && item.text === "Payments") || (openMore && item.text === "More")} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {item.submenus.map((submenu) => (
                      <Link
                        to={submenu.path}
                        onClick={()=>{
                          setChildSidebarText(submenu.text);
                        }}
                        style={{ 
                          textDecoration: "none", 
                          color: "inherit",
                        color: (childSidebarText === submenu.text) ? "#4169E1" : "inherit",
                      }}
                      >
                        <ListItemButton key={submenu.text} sx={{ pl: 4 }}>
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : "auto",
                              justifyContent: "center",
                            }}
                          >
                            {submenu.icon}
                          </ListItemIcon>
                          <ListItemText primary={submenu.text} />
                        </ListItemButton>
                      </Link>
                    ))}
                  </List>
                </Collapse>
              )}
            </ListItem>
          ))}
        </List>
      </Drawer>
    </SidebarContainer>
  );
}

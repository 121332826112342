import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/system";
import { GoArrowRight } from "react-icons/go";
import { IoMdClose } from "react-icons/io";
import { PackagegraphAnnually, PackagegraphWeekly, PackagegraphMonthly } from "./Packagegraph";
import { AttractiongraphAnnually, AttractiongraphMonthly, AttractiongraphhWeekly } from "./Attractiongraph";
import { LandcombographAnnually, LandcombographMonthly, LandcombographhWeekly } from "./Landcombograph";
import { TransfergraphAnnually, TransfergraphMonthly, TransfergraphWeekly } from "./Transfergraph";
import './Dashboard.css';
import { Link } from "react-router-dom";
import { APIPath, token } from "../../config/api";

const StyledDashboardContainer = styled(Grid)(({ theme, open }) => ({
  background: "rgba(204,204,204,0.2)",
  // paddingTop: "10px",
  marginTop: "1%",
  marginLeft: open ? theme.spacing(30) : theme.spacing(8),
  width: open ? "80%" : "94%",
  transition: "margin-left 0.3s ease",
}));

const Dashboard = ({ open }) => {
  document.body.style.overflow = 'auto';
  const [totalAgent, setTotalAgent] = useState();
  const [totalBookings, setTotalBookings] = useState();
  const [totalRevenue, setTotalRevenue] = useState();
  const [pendingBookings, setPendingBookings] = useState();
  const [annually, setAnnually] = useState(true);
  const [monthly, setMonthly] = useState(false);
  const [weekly, setWeekly] = useState(false);
  const [custom, setCustom] = useState(false);
  const [graphValue, setGraphValue] = useState('package');
  const [pendingBookingData, setPendingBookingData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch(`${APIPath}/api/v1/agency/dashboard/analytics-count`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      method: 'GET',
      mode: 'cors'
    }).then((res) => res.json())
      .then((data) => {
        console.log(data.data)
        setTotalAgent(data.data.totalAgents);
        setTotalBookings(data.data.totalBookings);
        setPendingBookings(data.data.pendingBookings);
        setTotalRevenue(data.data.totalRevenue);
      }).catch((err) => {
        console.log(err);
      })
  }, [])
  useEffect(() => {
    fetch(`${APIPath}/api/v1/agency/bookings`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: 'GET',
      mode: "cors",
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.data.slice(0, 4));
        setPendingBookingData(data.data.slice(0, 4))
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching Bookings list:', error);
        setIsLoading(false);
      });
  }, [])

  const onGraphChange = (e) => {
    setGraphValue(e.target.value);
  }

  return (
    <>
      <StyledDashboardContainer open={open}>
        <div className="dashboard-container">
          <h2>Dashboard</h2>
          <div className="dashboard-all-details">
            <div className="dashboard-agent-parent">
              <div className="dashboard-agent">
                <div className="dashboard-agent-detail">
                  <h4>Total Agents</h4>
                  <h2>{totalAgent}</h2>
                </div>
                <div>
                  <img src="agenticon.svg" height={60} width={60} />
                </div>
              </div>
              {/* <p className="dashboard-inc-text"><img src="/incrementgraph.svg" /><span style={{ color: "rgba(0, 182, 155, 1)" }}>8.5%</span>up from last month</p> */}
            </div>
            <div className="dashboard-agent-parent">
              <div className="dashboard-agent">
                <div className="dashboard-agent-detail">
                  <h4>Total Bookings</h4>
                  <h2>{totalBookings}</h2>
                </div>
                <div>
                  <img src="bookingicon.svg" height={60} width={60} />
                </div>
              </div>
              {/* <p className="dashboard-inc-text"><img src="/incrementgraph.svg" /><span style={{ color: "rgba(0, 182, 155, 1)" }}>8.5%</span>up from yesterday</p> */}
            </div>
            <div className="dashboard-agent-parent">
              <div className="dashboard-agent">
                <div className="dashboard-agent-detail">
                  <h4>Total Revenue</h4>
                  <h2> AED {totalRevenue}</h2>
                </div>
                <div>
                  <img src="revenueicon.svg" height={60} width={60} />
                </div>
              </div>
              {/* <p className="dashboard-inc-text"><img src="/incrementgraph.svg" /><span style={{ color: "rgba(0, 182, 155, 1)" }}>8.5%</span>up from yesterday</p> */}
            </div>
            <div className="dashboard-agent-parent">
              <div className="dashboard-agent">
                <div className="dashboard-agent-detail">
                  <h4>Pending Booking</h4>
                  <h2>{pendingBookings}</h2>
                </div>
                <div>
                  <img src="pendingbooking.svg" height={60} width={60} />
                </div>
              </div>
              {/* <p className="dashboard-inc-text"><img src="/incrementgraph.svg" /><span style={{ color: "rgba(0, 182, 155, 1)" }}>8.5%</span>up from yesterday</p> */}
            </div>

          </div>
          <div className="dashboard-graph-recent-booking">
            <div className="dashboard-graph">
              <div className="dashboard-graph-header">
                <h2>Sales Analytics</h2>
                <select onChange={(e) => onGraphChange(e)}>
                  <option value="package">All</option>
                  <option value="package">Package</option>
                  <option value="attraction">Attraction</option>
                  <option value="landcombo">Land Combo</option>
                  <option value="transfer">Transfer</option>
                </select>
              </div>
              <div className="dashboard-graph-a-m-w">
                <p className={annually ? "active" : ""}
                  onClick={() => {
                    setAnnually(true)
                    setMonthly(false)
                    setWeekly(false)
                    setCustom(false);
                  }}
                >Annually</p>
                <p
                  className={monthly ? "active" : ""}
                  onClick={() => {
                    setAnnually(false)
                    setMonthly(true)
                    setWeekly(false)
                    setCustom(false);
                  }}
                >Monthly</p>
                <p
                  className={weekly ? "active" : ""}
                  onClick={() => {
                    setAnnually(false)
                    setMonthly(false)
                    setWeekly(true)
                    setCustom(false);
                  }}
                >Weekly</p>
                <p className={custom ? "active" : ""}
                  onClick={() => {
                    setAnnually(false)
                    setMonthly(false)
                    setWeekly(false)
                    setCustom(true);
                  }}
                >
                  Custom
                </p>
                {custom && (
                  <>
                    <div className="dashboard-from-to-date-container">
                      <div className="dashboard-custom-clear">
                        <IoMdClose onClick={() => {
                          setCustom(false);
                        }} />
                      </div>
                      <div className="dashboard-from-to-date">
                        <div className="dashboard-from-date">
                          <h2>From</h2>
                          <input type="date" />
                        </div>
                        <div className="dashboard-to-date">
                          <h2>To</h2>
                          <input type="date" />
                        </div>
                      </div>
                      <div className="dashboard-graph-apply-btn">
                        <button>Apply</button>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="dashboard-graph-container">
                {(graphValue === 'package' && annually) && <PackagegraphAnnually />}
                {(graphValue === 'package' && monthly) && <PackagegraphMonthly />}
                {(graphValue === 'package' && weekly) && <PackagegraphWeekly />}
                {(graphValue === 'attraction' && annually) && <AttractiongraphAnnually />}
                {(graphValue === 'attraction' && monthly) && <AttractiongraphMonthly />}
                {(graphValue === 'attraction' && weekly) && <AttractiongraphhWeekly />}
                {(graphValue === 'landcombo' && annually) && <LandcombographAnnually />}
                {(graphValue === 'landcombo' && monthly) && <LandcombographMonthly />}
                {(graphValue === 'landcombo' && weekly) && <LandcombographhWeekly />}
                {(graphValue === 'transfer' && annually) && <TransfergraphAnnually />}
                {(graphValue === 'transfer' && monthly) && <TransfergraphMonthly />}
                {(graphValue === 'transfer' && weekly) && <TransfergraphWeekly />}

              </div>
            </div>
            <div className="dashboard-recent-booking">
              <h2>Recent Bookings</h2>
              {isLoading ? <div className="loader"></div> :
                <>
                  {pendingBookingData?.map((data, id) => {
                    return <>
                      {data.bookingID.includes('CUS') &&
                        <div className="dashboard-booking-pending">
                          <div>
                            <img src="packagesicon.svg" />
                          </div>
                          <div className="dashboard-recent-booking-title">
                            <h4>Custom</h4>
                            <p>{data.customerDetails?.name} | {data.createdAt?.split("T")[0]}</p>
                          </div>
                          <div className="dashboard-bookingStatus-and-price">
                            {/* <img src="pending.svg" /> */}
                            <p id={(data.bookingStatus === "Pending" && "pending") ||
                              (data.bookingStatus === "On Hold" && "on-hold") ||
                              (data.bookingStatus === "Awaiting Payment" && "Awaiting_Payment") ||
                              (data.bookingStatus === "Confirmed" && "confirm") ||
                              (data.bookingStatus === "Rejected" && "rejected")}
                            >{data.bookingStatus}</p>
                            <p id="dashboard-recent-booking-price">AED {data.totalCost}</p>
                          </div>
                        </div>
                      }
                      {data.bookingID.includes('PKG') &&
                        <div className="dashboard-booking-pending">
                          <div>
                            <img src="packagesicon.svg" />
                          </div>
                          <div className="dashboard-recent-booking-title">
                            <h4>Packages</h4>
                            <p>{data.customerDetails?.name} | {data.createdAt?.split("T")[0]}</p>
                          </div>
                          <div className="dashboard-bookingStatus-and-price">
                            {/* <img src="pending.svg" /> */}
                            <p id={(data.bookingStatus === "Pending" && "pending") ||
                              (data.bookingStatus === "On Hold" && "on-hold") ||
                              (data.bookingStatus === "Awaiting Payment" && "Awaiting_Payment") ||
                              (data.bookingStatus === "Confirmed" && "confirm") ||
                              (data.bookingStatus === "Rejected" && "rejected")}
                            >{data.bookingStatus}</p>
                            <p id="dashboard-recent-booking-price">AED {data.totalCost}</p>
                          </div>
                        </div>
                      }
                      {data.bookingID.includes('LNC') &&
                        <div className="dashboard-booking-pending">
                          <div>
                            <img src="landcombosicon.svg" />
                          </div>
                          <div className="dashboard-recent-booking-title">
                            <h4>Land Combos</h4>
                            <p>{data.customerDetails?.name} | {data.createdAt?.split("T")[0]}</p>
                          </div>
                          <div className="dashboard-bookingStatus-and-price">
                            {/* <img src="pending.svg" /> */}
                            <p id={(data.bookingStatus === "Pending" && "pending") ||
                              (data.bookingStatus === "On Hold" && "on-hold") ||
                              (data.bookingStatus === "Awaiting Payment" && "Awaiting_Payment") ||
                              (data.bookingStatus === "Confirmed" && "confirm") ||
                              (data.bookingStatus === "Rejected" && "rejected")}
                            >{data.bookingStatus}</p>
                            <p id="dashboard-recent-booking-price">AED {data.totalCost}</p>
                          </div>
                        </div>
                      }
                      {data.bookingID.includes('ATT') &&
                        <div className="dashboard-booking-pending">
                          <div>
                            <img src="attractionsicon.svg" />
                          </div>
                          <div className="dashboard-recent-booking-title">
                            <h4>Attractions</h4>
                            <p>{data.customerDetails?.name} | {data.createdAt?.split("T")[0]}</p>
                          </div>
                          <div className="dashboard-bookingStatus-and-price">
                            {/* <img src="pending.svg" /> */}
                            <p id={(data.bookingStatus === "Pending" && "pending") ||
                              (data.bookingStatus === "On Hold" && "on-hold") ||
                              (data.bookingStatus === "Awaiting Payment" && "Awaiting_Payment") ||
                              (data.bookingStatus === "Confirmed" && "confirm") ||
                              (data.bookingStatus === "Rejected" && "rejected")}
                            >{data.bookingStatus}</p>
                            <p id="dashboard-recent-booking-price">AED {data.totalCost}</p>
                          </div>
                        </div>
                      }
                      {data.bookingID.includes('TRF') &&
                        <div className="dashboard-booking-pending">
                          <div>
                            <img src="transfersicon.svg" />
                          </div>
                          <div className="dashboard-recent-booking-title">
                            <h4>Transfers</h4>
                            <p>{data.customerDetails?.name} | {data.createdAt?.split("T")[0]}</p>
                          </div>
                          <div className="dashboard-bookingStatus-and-price">
                            {/* <img src="pending.svg" /> */}
                            <p id={(data.bookingStatus === "Pending" && "pending") ||
                              (data.bookingStatus === "On Hold" && "on-hold") ||
                              (data.bookingStatus === "Awaiting Payment" && "Awaiting_Payment") ||
                              (data.bookingStatus === "Confirmed" && "confirm") ||
                              (data.bookingStatus === "Rejected" && "rejected")}
                            >{data.bookingStatus}</p>
                            <p id="dashboard-recent-booking-price">AED {parseInt(data.totalCost)}</p>
                          </div>
                        </div>
                      }
                    </>
                  })
                  }
                </>
              }
              <hr style={{ opacity: "0.2" }}></hr>
              {pendingBookingData?.length > 0 ?
                <div className="view-all-booking">
                  <Link to="/bookings">
                    <button>View all</button>
                  </Link>
                </div> : <div> <h4 style={{fontWeight:'500'}}>There is no booking request for now</h4> </div>
              }
            </div>
          </div>
        </div>
      </StyledDashboardContainer>
    </>
  );
};

export default Dashboard;

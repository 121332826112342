import * as React from "react";
import { styled } from "@mui/material/styles";
import TableContainer from "@mui/material/TableContainer";
import { Paper, } from "@mui/material";
import { APIPath, token } from "../../config/api";

const StyledTableContainer = styled(TableContainer)(({ theme, open }) => ({
    width: open ? "80%" : "94%",
    transition: "width 0.3s ease",
    paddingTop: "7px",
    marginLeft: open ? "243px" : "69px",
    position: "relative",
    borderRadius: "0px",
    overflow: "hidden",
    marginBottom: "20px",
    boxShadow: "none"
}));


const PackageDetail = ({ open, close, closeform, editedLandCombosId,
    title, description,
    coverImage, bannerImage,
    imageCoverP, imageBannerP,
    fromCountry, fromCountryName, 
    cityP, cityName,
    inclusion, exclusion,note,
    currency, childPrice, adultsPrice, loadPackagesList, expectation, cancellationPolicy
}) => {
    document.body.style.overflow = 'hidden';

    const [isLoading, setIsLoading] = React.useState(false);
    const method = editedLandCombosId?.length > 0 ? 'PATCH' : 'POST';
    const [imageCover, setImageCover] = React.useState(null);
    const [imageBanner, setImageBanner] = React.useState(null);

    const convertUrlToFile = async (url) => {
        const response = await fetch(url);
        const blob = await response.blob();
        const fileName = url.split('/').pop();
        return new File([blob], fileName, { type: blob.type });
    };

    const AddPackageData =
    {
        title: title,
        landComboOverview: description,
        includedServices: inclusion,
        excludedServices: exclusion,
        expectations: expectation,
        cancellationRefundPolicy: cancellationPolicy,
        location:
        {
            city: cityName,
            cityId:cityP,
            country: fromCountryName,
            countryId:fromCountry
        },
        cost: `AED ${adultsPrice} Per head , based on 2 Pax sharing`,
        price: [{
            adultPrice: adultsPrice,
            childPrice: childPrice,
        }],
        note: note
    }

    const AddNewPackage = async () => {
        const coverFile = await convertUrlToFile(imageCoverP);
        const bannerFile = await convertUrlToFile(imageBannerP);
        setImageCover(coverFile);
        setImageBanner(bannerFile);
        sendDataToServer(AddPackageData, coverFile, bannerFile);
    };
    const sendDataToServer = async (AddPackageData, imageCover, imageBanner) => {
        try {
            showLoader();
            const formData = new FormData();
            formData.append('jsonData', JSON.stringify(AddPackageData));
            formData.append('thumbnail', imageCover);
            formData.append('banner', imageBanner);
            const response = await fetch(`${APIPath}/api/v1/agency/land_combos?id=${editedLandCombosId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                method: method,
                mode: 'cors',
                body: formData
            });

            hideLoader();
            if (!response.ok) {
                console.log(response);
                alert("Failed to add Package");
                return;
            }
            if (method === 'POST') {
                alert("New LandCombos added...");
            }
            if (method === 'PATCH') {
                alert("LandCombos updated...")
            }
            const responseData = await response.json();
            close();
            closeform();
            loadPackagesList();
        } catch (error) {
            console.error('Error sending data to server:', error);
            hideLoader();
            alert('An error occurred while adding the Landcombos');
        }
    };

    const showLoader = () => {
        setIsLoading(true);
    };

    const hideLoader = () => {
        setIsLoading(false);
    };

    return <StyledTableContainer component={Paper} open={open}>
        <div className="admin-package-detail-container" >
            <div className="admin-package-detail-page">
                <div className="back-and-text">
                    <div className="back">
                        <button onClick={close} style={{ background: "none", border: "none", cursor: "pointer" }}>
                            <img src="backicon.svg" alt="back-icon" />
                        </button>                        </div>
                    <div className="text">
                        <h2>LandCombos Details</h2>
                    </div>
                </div>
                <div className="package-basic-details">
                    <h3>Basic Details</h3>
                    <div className="admin-pkg-title-description">
                        <div className="title">
                            <h4>Title</h4>
                        </div>
                        <div className="title-value">
                            <h5>{title}</h5>
                        </div>
                        <div className="description">
                            <h4>Description</h4>
                        </div>
                        <div className="description-value">
                            <h5>{description}</h5>
                        </div>
                    </div>
                    <div className="admin-pkg-title-description-pic">
                        <div className="title">
                            <h4>Cover Photo</h4>
                        </div>
                        <div className="title-value">
                            <img src={coverImage} height="100px" width="150px" />
                        </div>
                        <div className="description">
                            <h4>Banner Photo</h4>
                        </div>
                        <div className="description-value">
                            <img src={bannerImage} height="100px" width="150px" />
                        </div>
                    </div>
                </div>
                <div className="package-basic-details">
                    <h3>Location Details</h3>
                    <div className="location-details">
                        <div className="title">
                            <h4>Country</h4>
                        </div>
                        <div className="title-value">
                            <h5>{fromCountryName}</h5>
                        </div>
                        <div className="description">
                            <h4>City</h4>
                        </div>
                        <div className="description-value">
                            <h5>{cityName}</h5>
                        </div>
                    </div>
                </div>
                <div className="package-form-inclusion-exclusion">
                    <h2>Cancellation Policy</h2>
                    <div className="package-form-cancellation-polocy">
                        {cancellationPolicy.map((val, id) => {
                            return <ul key={id}>
                                {val && <li>{val}</li>}
                            </ul>
                        })}
                    </div>
                </div>
                <div className="package-basic-details">
                    <h3>Inclusions/Exclusions</h3>
                    <div className="location-details">
                        <div className="title">
                            <h4>Inclusions</h4>
                        </div>
                        <div className="title-value">
                            {inclusion?.map((val, id) => {
                                return <>
                                    <ol>
                                        <p>{val}</p>
                                    </ol>
                                </>
                            })}
                        </div>
                        <div className="description">
                            <h4>Exclusions</h4>
                        </div>
                        <div className="description-value">
                            {exclusion?.map((val, id) => {
                                return <>
                                    <ol>
                                        <p>{val}</p>
                                    </ol>
                                </>
                            })}
                        </div>

                    </div>
                </div>
                <div className="package-basic-details">
                    <h3>Meeting Points</h3>
                    {expectation?.map((val, id) => {
                        return <>
                            <div className="location-details">
                                <div className="title">
                                    <h4>Location:</h4>
                                </div>
                                <div className="title-value">
                                    <h5>{val.location}</h5>
                                </div>
                                <div className="description">
                                    <h4>Description:</h4>
                                </div>
                                <div className="description-value">
                                    <h5>{val.description}</h5>
                                </div>
                                {/* <div className="description">
                                    <h4>Open Time:</h4>
                                </div>
                                <div className="description-value">
                                    <h5>{val.time}</h5>
                                </div> */}
                            </div>
                        </>
                    })}
                </div>
                <div className="package-basic-details">
                   <h3>Note</h3>
                   <h5>{note}</h5>
                </div>
                <div className="package-price">
                    <h3>Pricing</h3>
                    <div>
                        <p>Adults Price</p>
                        <h4>{currency} {adultsPrice}</h4>
                    </div>
                    <div>
                        <p>Child Price</p>
                        <h4>{currency} {childPrice}</h4>
                    </div>
                </div>
                <div className="package-delete-edit-btn">
                    {isLoading ? (<div className="loader"></div>) : (
                        <>
                            <button id="delete" onClick={close}>Back</button>
                            <button id="edit" onClick={AddNewPackage} disabled={isLoading}>Submit</button>
                        </>
                    )}
                </div>
            </div>
        </div>
    </StyledTableContainer>
};

export default PackageDetail;
